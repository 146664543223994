import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { OidcService } from '../oidc/oidc.service';
import { PartnerService } from '../shared/utils/partner.service';
import { OAuthService } from 'angular-oauth2-oidc';
import {ApiRegistrationService} from "../shared/api/api.registration.service";
import {Store} from "@ngrx/store";
import UserProfile from "../shared/models/userProfile";
import {isObjectNullOrEmpty} from '../shared/utils/objectNullOrEmpty';

/**
 * Class that blocks access to routes which are intended for authorized users
 */
@Injectable()
export class RouteGuardService implements CanActivate {
  userProfile: UserProfile;

  constructor(
    private oidcService: OidcService,
    private oauthService: OAuthService,
    private partnerService: PartnerService,
    private apiRegistrationService: ApiRegistrationService,
    private router: Router,
    private store: Store<{ 'userProfile': UserProfile }>
  ) {
    this.store.subscribe(userProfile => {
      if(userProfile && userProfile.userProfile) {
        this.userProfile = userProfile.userProfile;
      }
    });
  }

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    await this.oauthService.loadDiscoveryDocument();

    await this.handleIncompleteRegistration();

    if (this.partnerService.isPartnerVital10()) {
      return state.url.indexOf('partner') !== -1 || state.url.indexOf('login-vital10') !== -1;
    }

    const allowedToAccessRoute = this.oidcService.isAuthenticated() && !this.partnerService.isPartnerVital10();
    if (!allowedToAccessRoute) {
      // check for refresh token
      const refreshToken = localStorage.getItem('refresh_token');
      if (refreshToken) {
        await this.oidcService.refreshToken();
        return true;
      }

      console.error('NOT AUTHORIZED TO ACCESS ROUTE!');
      return this.router.parseUrl('/');
    }
    return true;
  }

  private async handleIncompleteRegistration(): Promise<boolean> {
    if(this.oidcService.isAuthenticated() && this.isRequiredRegistrationFieldNullOrEmpty()) {
      const step = await this.apiRegistrationService.getIncompleteRegistrationSteps();
      if (step) {
        await this.router.navigateByUrl('/register/' + step);
        return false;
      }
    }
    return true;
  }

  isRequiredRegistrationFieldNullOrEmpty() {
    return isObjectNullOrEmpty(this.userProfile?.generalInformation?.firstName) ||
          isObjectNullOrEmpty(this.userProfile?.generalInformation?.lastName) ||
          isObjectNullOrEmpty(this.userProfile?.generalInformation?.gender) ||
          isObjectNullOrEmpty(this.userProfile?.generalInformation?.phoneNumber);
  }
}
