import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import AdyenCheckout from '@adyen/adyen-web';
import { ApiPaymentService } from '../../api/api-payment.service';
import { environment } from '../../../../environments/environment';
import { Store } from "@ngrx/store";
import UserProfile from "../../models/userProfile";
import { take } from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../api/api.service';
import PaymentInfo from '../../../shared/models/paymentInfo';
import {Subscription} from 'rxjs';

@Component({
  selector: 'payment-dropin',
  templateUrl: './payment-dropin.component.html',
  styleUrls: ['./payment-dropin.component.scss']
})
export class PaymentDropinComponent implements OnInit {
  @Output() paymentFinalized = new EventEmitter<string>();
  sub: Subscription;

  countryCode = 'NL';
  cultureCode = 'nl-NL';
  paymentInfo: PaymentInfo;
  subscriptionAmount = 2.99;
  currencyCode = "EUR";

  constructor(
    private apiPaymentService: ApiPaymentService,
    private translate: TranslateService,
    private store: Store<{ 'userProfile': UserProfile }>,
    readonly apiService: ApiService
  ) {

  }

  ngOnInit(): void {
    this.sub = this.store.pipe(take(1)).subscribe(store => {
      this.countryCode = store.userProfile.generalInformation.nationality;

      this.setPaymentInfo().then(() => {
        this.apiPaymentService.getPaymentMethods(this.countryCode).subscribe(res => {
          document.getElementById('dropin-container').scrollIntoView();
          const configuration = {
            paymentMethodsResponse: {
              paymentMethods: res.paymentMethods
            }, // The `/paymentMethods` response from the server.
            clientKey: environment.adyenClientKey, // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
            locale: this.cultureCode,
            environment: environment.production ? 'live' : 'test',
            returnUrl: `${environment.siteBaseUrl}dashboard/settings/financial`,
            amount: {
              currency: this.currencyCode,
              value: (this.subscriptionAmount * 100)
            },
            // @ts-ignore
            onSubmit: (state: any, component: any) => {
              if (state.isValid) {
                this.initiatePayment(state, component);
              }
            },
            onAdditionalDetails: (state: any, component: any) => {
              this.submitAdditionalDetails(state, component);
            },
            paymentMethodsConfiguration: {
              card: { // Example optional configuration for Cards
                hasHolderName: true,
                holderNameRequired: true,
                enableStoreDetails: true,
                hideCVC: false, // Change this to true to hide the CVC field for stored cards
                name: 'Credit or debit card',
              },
              threeDS2: {
                challengeWindowSize: '02'
                // Set to any of the following:
                // '02': ['390px', '400px'] -  The default window size
                // '01': ['250px', '400px']
                // '03': ['500px', '600px']
                // '04': ['600px', '400px']
                // '05': ['100%', '100%']
              }
            }
          };

          const checkout = AdyenCheckout(configuration);
          const dropin = checkout.then(checkout => {
            checkout.create('dropin', {

              // Starting from version 4.0.0, Drop-in configuration only accepts props related to itself and cannot contain generic configuration like the onSubmit event.

              openFirstPaymentMethod:false

            }).mount('#dropin-container');
          })

        });
      });
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async initiatePayment(state: any, component: any) {
    try {
      state.data.returnUrl = `${environment.siteBaseUrl}dashboard/settings/financial`;
      state.data.countryCode = this.countryCode;
      this.apiPaymentService.initiatePayment(state.data).subscribe((res) => {
        this.handleServerResponse(res, component);
      });
    } catch (error) {
      console.error(error);
    }
  }

  async submitAdditionalDetails(state: any, component: any) {
    try {
      this.apiPaymentService.submitAdditionalDetails(state.data).subscribe((res) => {
        this.handleServerResponse(res, component);
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleServerResponse(res: any, component: any) {
    if (res.action) {
      component.handleAction(res.action);
    } else {
      this.paymentFinalized.emit(res.resultCode);
    }
  }

  async setPaymentInfo() {
    this.cultureCode = this.translate.currentLang;
    
    this.paymentInfo = await this.apiService.getPaymentInfo();
    if(this.paymentInfo != null) {
      this.subscriptionAmount = this.paymentInfo.monthlySubscriptionAmount;
      this.currencyCode = this.paymentInfo.currencyCode;
    }
  }
}
