import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from 'rxjs';

@Component({
    selector: 'premium-info',
    templateUrl: './premium-info.component.html',
    styleUrls: ['./premium-info.component.scss']
  })
export class PremiumInfoComponent implements OnInit {
  sub: Subscription;
  premiumCheckpoints: string[] = [];
  
  constructor(
    public modal: NgxSmartModalService,
    private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.sub = this.translate.onLangChange.subscribe(
      () => {
        this.loadCheckpointTranslations();
      });

    this.loadCheckpointTranslations();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  loadCheckpointTranslations() {
    let translatedCheckpoints = this.translate.instant(`PREMIUM_INFO.CHECK_POINTS`);
    this.premiumCheckpoints = translatedCheckpoints.split('\n').map((item: string) => item.trim());
  }
}
