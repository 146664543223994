<ngx-smart-modal
  [identifier]="modalId"
  [closable]="false"
  [hideDelay]="200"
  customClass="include-padding small-modal"
  class="premium-popup-wrapper"
  #premiumModal
>
  <premium-info></premium-info>
  <div class="buttons-wrapper">
    <app-button class="premium-popup-button" (click)="goToPayment()" [buttonText]="('PREMIUM_POPUP.PURCHASE_MONTH' | translate) + MONTH_PRICE_STRING"></app-button>
<!--    <app-button class="premium-popup-button" [buttonText]="('PREMIUM_POPUP.PURCHASE_YEAR' | translate) + YEAR_PRICE_STRING"></app-button>-->
    <app-button class="premium-popup-button cancel-button-white" (click)="modal.getModal(modalId).close()" [buttonText]="'PREMIUM_POPUP.CANCEL_BUTTON' | translate" [styles]="[BUTTON_STYLES.secondaryAlternative]"></app-button>
    <div [innerHTML]="'PREMIUM_POPUP.AGREE_PRIVACY_POLICY_TERMS' | translate" style="font-size:1.1rem; margin-top:1rem;"></div>
  </div>
</ngx-smart-modal>
