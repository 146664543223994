import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Subscription} from 'rxjs';

const RANDOM_PROFILE_PICS = [
  null,
  'https://i.imgur.com/d4vO4V1.png',
  'https://cdn.sanity.io/images/0vv8moc6/hcplive/0ebb6a8f0c2850697532805d09d4ff10e838a74b-200x200.jpg',
  'https://ca.slack-edge.com/T6HECLLP9-U9TNMCGSV-786427131504-512',
  'https://linustechtips.com/uploads/monthly_2017_07/5964cf69b8074_mrburnsmemeinjectionstoned.thumb.png.65d36b16895c610755f93ad17f4b872e.png',
  'https://image.flaticon.com/icons/png/512/168/168732.png',
  'https://image.flaticon.com/icons/png/512/168/168724.png',
  'https://www.vegvisits.com/assets/04ed1861.png',
  'https://styles.redditmedia.com/t5_cb41l/styles/profileIcon_snoo3d475f0b-d397-4bba-981d-2d9413f3d42f-headshot.png?width=256&height=256&crop=256:256,smart&s=dc5cb59533c6a8bbaa1251bc20f1698089536326',
  'https://login.vivaldi.net/profile/avatar/justintaylor/L124UMZJYVPPfZ39.png',
  'https://pyxis.nymag.com/v1/imgs/630/6e0/eb215ad90cd826b9e57ff505f54c5c7228-07-avatar.rsquare.w700.jpg',
  'https://pickaface.net/gallery/avatar/humjk539f22350f79d.png',
  'https://cdn.kastatic.org/images/avatars/svg/aqualine-ultimate.svg',
];

@Injectable({
  providedIn: 'root'
})

/** Class that helps with formatting / calculating number related features */
export class NumberService {
  sub: Subscription;
  CURRENCY_FORMATTER = new Intl.NumberFormat();

  constructor(
    private translateService: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.sub = this.translateService.onLangChange.subscribe(() => {
        this.setCurrencyFormatter(this.translateService.currentLang);
    });

    this.setCurrencyFormatter(this.translateService.currentLang);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  // todo support decimals
  /**
   * Formats a number based on the browsers locale as a string
   * @param {number} value
   * @param roundToDecimals
   * @param absoluteValue Whether a negative number should remove the minus '-' in the returned string.
   * @returns {string}
   */
  formatNumber(value: number, roundToDecimals = 0, absoluteValue = false): string {
    const numberFormatter = new Intl.NumberFormat(this.translateService.getBrowserCultureLang(), {
      // style: 'unit',
      maximumFractionDigits: roundToDecimals,
      minimumFractionDigits: roundToDecimals
    });
    if (absoluteValue && value < 0) value *= -1;

    if (isNaN(value)) {
      return '0';
    }

    return numberFormatter.format(value);
  }

  /**
   * Rounds a number to x decimals
   * @param {number} value
   * @param {number} roundToDecimals
   * @returns {number}
   */
  roundNumberToDecimals(value: number, roundToDecimals = 0): number {
    return Number(Math.round(Number(value + 'e' + roundToDecimals)) + 'e-' + roundToDecimals);
  }

  /**
   * Format bytes as human-readable text.
   *
   * @param bytes Number of bytes.
   * @param si True to use metric (SI) units, aka powers of 1000. False to use
   *           binary (IEC), aka powers of 1024.
   * @param decimals Number of decimal places to display.
   *
   * @return Formatted string.
   */
  getFileSizeStringByFileSizeBytes(bytes: number, si = true, decimals = 0): string {
    const threshHold = si ? 1000 : 1024;

    if (Math.abs(bytes) < threshHold) {
      return bytes + 'B';
    }

    const units = si
      ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** decimals;

    do {
      bytes /= threshHold;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= threshHold && u < units.length - 1);

    return bytes.toFixed(decimals) + units[u];
  }

  /**
   * Gets a random integer between min and max (including min and max)
   * @param {number} min
   * @param {number} max
   * @returns {number}
   */
  getRandomIntBetween(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  /**
   * Gets a random float between min and max (including min and max)
   * @param {number} min
   * @param {number} max
   * @returns {number}
   */
  getRandomFloatBetween(min: number, max: number): number {
    return Math.random() * (max - min + 1) + min;
  }

  /**
   * Gets a random profile picture, with optionally a chance on null.
   * @param {boolean} includeChanceOnNull
   * @returns {string | null}
   */
  getRandomProfilePicture(includeChanceOnNull = true): string | null {
    return RANDOM_PROFILE_PICS[this.getRandomIntBetween(includeChanceOnNull ? 0 : 1, RANDOM_PROFILE_PICS.length - 1)];
  }

  getCurrencyValue(number: number): string {
    return this.CURRENCY_FORMATTER.format(number);
  }

  setCurrencyFormatter(local:string, currencyCode: string = 'EUR') {
    this.CURRENCY_FORMATTER = new Intl.NumberFormat(local, {
      style: 'currency',
      currency: currencyCode
    });
  }
}
